import React from "react"
import { Link } from "gatsby"
import classNames from 'classnames'
import Img from "../components/image-transform"
import ScheduleADemoButton from '../components/schedule-a-demo-button'

const PlanAction = ({content}) => {
  return (
    <div className="section-plan-action">
      <div className="container">
        <div className="content-wrapper">
          <div className="image-holder">
            {
              content.svg ?
              <img src={content.image} className="image" alt={content.imageName}/>
              :
              <Img filename={content.image} className="image" alt={content.imageName}/>
            }
          </div>

          <div className="text-holder">
            <h2 className="title">{content.title}</h2> 
            <p className="description">{content.description}</p> 

            <div className="cards">
              {
                content.cards.map((item, i) => {
                  return (
                    <div key={i} className="card-item">
                      <div className="card-image">
                      {
                        item.svg ?
                        <img src={item.image} className="image" alt={item.imageName}/>
                        :
                        item.image &&
                        <Img filename={item.image} className="image" alt={item.imageName}/>
                      }
                      </div>
                      <div className="card-text">
                        <p >{item.text}</p>
                      </div>
                      {
                        item.logo && 
                        <div className="logo">
                          {
                          item.svgLogo ?
                          <img src={item.logo} alt={item.logoName}/>
                          :
                          <Img filename={item.logo} alt={item.logoName}/>
                          }     
                        </div>
                      }
                    </div>
                  )
                })
              }
            </div>

            { content.button !== undefined &&
              <div className="buttons">
                {content.buttonText &&              
                  <Link to={content.url} className={classNames('btn', 
                        {"btn-orange" : content.buttonColor === "orange"},
                        {"btn-white" : content.buttonColor === "white"},
                        {"btn-black" : content.buttonColor === "black"},
                        {"btn-blue" : content.buttonColor === "blue"},
                  )}> 
                  {content.buttonText}
                  </Link>
                }
                {/* <div > */}
                <p className="coming-soon-text">{content.comingSoonText}</p>
                {/* </div> */}
              </div>
            }

            {content.buttons !== undefined &&
              <div className="button-holder">
                {content.buttons.map((item, i) => {
                  return (
                    item.scheduleButton ?
                      <ScheduleADemoButton key={i} buttonColor={item.buttonColor} /> : 
                      item.isExternal ?
                        <a href={item.url} key={i} target="_blank" rel="noopener noreferrer"
                          className={classNames('btn',
                            { "btn-orange": item.buttonColor === "orange" },
                            { "btn-white": item.buttonColor === "white" },
                            { "btn-black": item.buttonColor === "black" }
                          )}>{item.buttonText}</a>
                        :
                        <Link to={item.url} key={i}
                          className={classNames('btn',
                            { "btn-orange": item.buttonColor === "orange" },
                            { "btn-white": item.buttonColor === "white" },
                            { "btn-black": item.buttonColor === "black" }
                          )}>{item.buttonText}</Link>
                  )
                })}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlanAction