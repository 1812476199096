import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"

const robots = ({ content }) => (
  <div
    className={classNames(
      "robot-holder ",
      { "bg-white": content.bgColor === "white" },
      { "bg-blue": content.bgColor === "blue" }
    )}
  >
    <div className="container">
      <h2
        className={classNames(
          "heading",
          { "text-black": content.titleColor === "black" },
          { "text-white": content.titleColor === "white" }
        )}
      >
        {content.title}
      </h2>

      <div className="robot-flex">
        {content.block.map((block, i) => {
          return (
            <>
              <Link to={block.url} key={i}>
                <div className="robot-blocks">
                  <img src={block.img} alt={block.atlText} />
                  <p className="text">{block.text}</p>
                </div>
              </Link>
              <hr className="breaker" />
            </>
          )
        })}
      </div>
    </div>
  </div>
)

export default robots
