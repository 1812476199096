import React from 'react'

import Layout from '../../components/layout'
import SEO from "../../components/seo"
import TwoColumn from "../../components/two-column"
import Robots from "../../components/robots"
import CustomerJourney from '../../components/customer-journey'
import Columnar from '../../components/columnar'
import PlanAction from '../../components/plan-action'
import {solutionPlanActionContent} from "../../lib/solution-plan-action"
import StackedTexts from '../../components/stacked-texts'
import SingleContent from "../../components/single-column"

const bannerContent1 = {
  title: "Billsby is the best subscription billing software for your property or utilities business.",
  description: [
    {
      text: "Easy setup, high quality self-service for discerning time starved customers and well designed retention tools make Billsby the best recurring payments system for property and utility companies."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today'
    },
    {
      scheduleButton: true,
      buttonColor: 'black'
    }
  ],
  sectionSolutions: true,
  image: 'solution-property-utility.png',
  imageName: 'solution-property',
  backgroundColor: '#f3f5fb',
  textColor: '#252422',
  imagePosition: 'right',
  isBanner: true
}

const setupQuickly = {
  title: "Get setup quickly and easily and speed up your time to market.",
  list: [
    {
      title: 'No or minimal development required',
      description: <>We've invested millions of dollars and tens of thousands of development hours into building a flexible billing platform so that you and your team don't have to. Our system covers hundreds of billing scenarios, and lets you get setup in as little as a few hours and starting to charge customers.</>,
    },
    {
      title: "Don't worry about changes to rules and regulations ",
      description: <>Don't worry about changes to rules and regulations The payments industry is constantly changing rules and regulations, which can really slow down your SaaS business. With Billsby, as rules change we'll ensure your billing solution is automatically updated and adapted to suit them. So you don't need to watch your back.</>,
    }
  ],
  image: 'property-utilities-1.png',
  imageName: 'Property Utilities image',
}

const commercialFlexibility = {
  title: "Make changes in real time with complete commercial flexibility.",
  list: [
    {
      title: 'Create unlimited products, plans and cycles',
      description: <>CYou can create new products, plans and cycles whenever you want with Billsby - and they're all fully addressable through the API. Because of our unique approach to product, plan and cycle creation, and Feature Tags for access control, adding new plans doesn't require development intervention.</>,
    },
    {
      title: 'Switch payment gateways at any time',
      description: <>If there's a contract change, or you're required to do regular RFPs for payment gateways, you don't want to be stuck with not being able to setup a new integration. You can switch customers immediately to any supported gateway with Billsby.</>,
    }
  ],
  image: 'property-utilities-2.png',
  imageName: 'Property Utilities image',
  align: 'right'
}

const manageDunning = {
  title: "Understand why your customers leave and manage dunning.",
  list: [
    {
      title: 'Prevent and reduce churn',
      description: <>Customers leave, but our retention tools can help minimise the impact of churn on your business and explain why they leave when they choose to do so. This helps you to grow your business more quickly, and make changes to your product to stop inhibiting your growth.</>,
    },
    {
      title: 'Dunning is done for you',
      description: <>for you When payments decline, it's important to reach out to customers and get them back on track. Our advanced dunning tools make this process easy, and ensure that as much revenue is captured for your business as possible. All without writing a single line of code.</>,
    }
  ],
  image: 'property-utilities-3.png',
  imageName: 'Property Utilities image',
  align: 'left'
}

const robotContent = {
  bgColor: 'blue',
  title: 'Our top five features for for property and utility companies',
  titleColor: 'white',
  block: [
    {
      img: require('../../images/checkout-with-billsbot.svg'),
      altText: 'CHECKOUT AND PAYMENTS',
      text: 'CHECKOUT AND PAYMENTS',
      url: '/product/subscribers/checkout-and-payments',
    },
    {
      img: require('../../images/button-retention.svg'),
      altText: 'DUNNING AND RETENTION',
      text: 'DUNNING AND RETENTION',
      url: '/product/subscribers/dunning-retention',
    },
    {
      img: require('../../images/button-addons.svg'),
      altText: 'ADD-ONS AND ALLOWANCES',
      text: 'ADD-ONS AND ALLOWANCES',
      url: '/product/creating/addons-allowances',
    },
    {
      img: require('../../images/billsbot-usa.svg'),
      altText: 'SALES TAX',
      text: 'SALES TAX',
      url: '/product/subscribers/sales-tax-and-compliance',
    },
    {
      img: require('../../images/button-invoice-emails.svg'),
      altText: 'INVOICES AND EMAILS',
      text: 'INVOICES AND EMAILS',
      url: '/product/subscribers/invoices-creditnotes-emails',
    }
  ]
}

const columnarContent = [
  {
    title: "Reduce your PCI-DSS compliance burdens and headaches.",
    list: [
      {
        title: '',
        description: <>Billsby is PCI-DSS compliant and whether you use our JavaScript checkout or our card tokenizer, you'll never store payment card numbers or details anywhere on your server or infrastructure, or have to transfer them to us. This significantly reduces your compliance challenges.</>,
      },
    ]
  },
  {
    title: "Spend less time integrating systems and services.",
    list: [
      {
        title: '',
        description: <>You'll never have to worry about payment gateway integrations again - and your business will even have the flexibility to switch gateways at any time with no impact on you or your development cycle - it only takes a click to immediately start billing through an alternate provider.</>,
      },
    ]
  }
]

const behindPaywalls = {
  image: require('../../images/support-talk.svg'),
  imageName: 'billsby-payment',
  svg: true,
  stackedTexts : [
    {
      title: "We're here to help you get started, get setup and see quick ROI.",
      list: [
        {
          title: '',
          description: <>Our account management team can help you to get setup with Billsby, answer your implementation questions and point you in the right direction to get started. If you're doing development in house, our developer community, email support, chat support and phone call-backs are included with every plan.</>,
        },
        {
          title: '',
          description: <>If you need development assistance, we can help with Pro Development Services too - our all-inclusive end-to-end developed for you solution that can integrate Billsby into your existing business and processes at a surprisingly affordable price. Let us know how we can help you to succeed.</>,
        },
      ],
      buttons: [
        {
          scheduleButton: true,
          btnColor: 'blue'
        },
        {
          btnColor: 'blue',
          btnText: 'Pro Development Services',
          urlType: 'external',
          url: '/pro/development'
        }
      ]
    }
  ]
}

const singleContent3 =   {
  title: "Explore away, there's no credit card required",
  text: "We know that sometimes the best way to evaluate a solution is to try it for yourself, so we've made sure that signing up doesn't require a credit card, and our onboarding team are standing by to help with any questions.",
  button: [
    {
      buttonColor: "orange",
      url: "https://app.billsby.com/registration",
      btnText: "Sign up today",
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ]
}

const travel = () => {
  return (
    <Layout className="solutions property-utilities">
      <SEO 
        title="Property & utilities business subscription billing | Billsby" 
        description="Quick & easy subscription billing software for property or utility business needs with customer retention tools to reduce churn and accounting reports | Billsby" 
        url="https://www.billsby.com/solutions/property-and-utilities"
      />

      <TwoColumn content={bannerContent1}/>
      <CustomerJourney content={setupQuickly} />
      <CustomerJourney content={commercialFlexibility} />
      <CustomerJourney content={manageDunning} />
      <Robots content={robotContent}/>
      <Columnar content={columnarContent} />
      <PlanAction content={solutionPlanActionContent} />
      <StackedTexts content={behindPaywalls} />
      {/* <SingleContent content={singleContent3} /> */}
    </Layout>
  )
}

export default travel